import { keyframes } from '@emotion/css';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { NextRouter, useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';
import { AiFillPlayCircle, AiOutlineUser } from 'react-icons/ai';
import { BiTimeFive } from 'react-icons/bi';
import { CgFormatSlash } from 'react-icons/cg';
import { FaRegEye } from 'react-icons/fa';
import { RxTriangleRight } from 'react-icons/rx';
import { IMAGE_SIZE } from 'src/components/image';
import { APP_IMG, DEFAULT_COUNT_VIEW } from 'src/constants';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { IArticle } from 'src/interface/section';
import { useCountviews } from 'src/utils/countview';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { convertBadge, numeralFormat, splitRote, timestamp, useDateUpdate } from 'src/utils/helper';

const Image = dynamic(import('src/components/image'));

type Props = {
  color?: string;
  className?: string;
  data?: IArticle | any;
  showBadge?: boolean;
  showTitle?: boolean;
  showBlurb?: boolean;
  showByline?: boolean;
  showPublishDate?: boolean;
  showCountView?: boolean;
  showReadmore?: boolean;
  showTimeAgo?: boolean;
  showFooter?: boolean;
  eventDataLayer?: IDataLayerGlobal;
  target?: string;
  external?: boolean;
  path?: string;
  size?: any;
  showVideoIcon?: boolean;
  publishedAtKey?: 'published_at' | 'updated_at';
};
const CardH: NextPage<Props> = ({
  color = '#000',
  className = '',
  data,
  showBadge = true,
  showTitle = true,
  showBlurb = true,
  showByline = false,
  showPublishDate = true,
  showReadmore = false,
  showCountView = false,
  showFooter = true,
  eventDataLayer = {},
  target = '',
  external = false,
  path = '',
  size,
  showVideoIcon = false,
  publishedAtKey = 'published_at'
}) => {
  if (isEmpty(data)) return null;

  const [countView, setCountView] = useState<number>(0);
  const router: NextRouter = useRouter();

  const conditionCountview: boolean = showCountView && countView > DEFAULT_COUNT_VIEW;
  const conditionBadge: string | false = showBadge && convertBadge(data, false);

  useEffect(() => {
    (async () => {
      try {
        const _pageviews: number = await useCountviews(data);
        setCountView(_pageviews);
      } catch (err: any) {
        console.error(`${timestamp()} ==========> CARD_FULL ERROR :`, err.message);
      }
    })();
  }, [data]);

  return (
    <CardWrapper className={`${className} card-h`} color={color}>
      <a
        href={`${external ? `${data?.link || path}` : `${splitRote(router)}${data?.link || path}`}`}
        aria-label={data?.title}
        target={`${target === 'blank' ? '_blank' : '_self'}`}
        rel={`${target === 'blank' ? 'noopener noreferrer' : ''}`}
        title={data?.title}
        tabIndex={-1}
        className='card-h-href'
        // ! DATALAYER
        onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
      >
        {/* ---------------------------------- IMAGE ---------------------------------  */}
        <div className='card-image'>
          <Image image={data?.image || '/default.jpg'} title={data?.title} external={external} size={size || IMAGE_SIZE.LG} />

          {data.is_video === true && (
            <div className='play-btn'>
              <AiFillPlayCircle size={35} />
            </div>
          )}
          {
            showVideoIcon &&
            <div className='play-btn-gallery'>
              <RxTriangleRight size={19} />
            </div>
          }
        </div>
        <div className='card-detail'>
          {/* ---------------------------------- CONTENT -------------------------------  */}
          <div className='card-content'>
            {!!conditionBadge && (
              <div className='badge'>
                <small className='line-clamp --1'>{conditionBadge}</small>
              </div>
            )}
            {data?.id === 'skeleton' && (
              <div className='skeleton-title'>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            {showTitle && <h3 className='title line-clamp --2'>{data?.title}</h3>}
            {showBlurb && <small className='blurb line-clamp --3'>{data?.blurb || ''}</small>}
          </div>

          {/* ------------------------------ CONTENT-FOOTER ----------------------------  */}
          {showFooter && (
            <div className='card-footer'>
              <div className='center-content'>
                {showByline && (
                  <>
                    {data.byline && (
                      <p className='byline'>
                        <AiOutlineUser /> {data.byline}
                      </p>
                    )}
                  </>
                )}
                {showPublishDate && (
                  <>
                    {data?.[publishedAtKey] && (
                      <div className='published-date'>
                        <BiTimeFive size={12} />
                        <small>{useDateUpdate(data[publishedAtKey])}</small>
                      </div>
                    )}
                  </>
                )}
                {/* {
                  <div className='published-date'>
                    <BiTimeFive size={12} />
                    <small>{useDateUpdate(data?.created_at)}</small>
                  </div>
                } */}
                {conditionCountview && (
                  <div className='count-view'><CgFormatSlash size={14} />
                    <FaRegEye size={12} />
                    <small>{numeralFormat(countView)}</small>
                  </div>
                )}
              </div>
              <div className='footer-right'>
                {showReadmore && (
                  <div className='readmore'>
                    <small>อ่านต่อ</small>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </a>
    </CardWrapper>
  );
};

const rotate = keyframes`
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(1000%, 0);
  }
`;

const CardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  border: 1px solid #93939355;

  &.ch-border-none {
    border: none;
  }

  &.section-vdo {
    border: unset;
    .card-footer {
      justify-content: flex-start;
      .published-date {
        small,
        svg {
          color: #000 !important;
        }
      }
    }
  }

  &.skeleton-loading {
    .title {
      display: none;
    }
    .skeleton-title {
      border-radius: 50px;
      & > div {
        position: relative;
        overflow: hidden;
        height: 15px;
        margin: 5px 0;
        border-radius: 50px;
        background-color: #8f8f8f93;
        width: 100%;
        &:nth-child(2) {
          width: 70%;
          &:before {
            content: '';
            position: absolute;
            background-color: #b4b3b3ac;
            height: 15px;
            width: 30px;
            transform: rotate(116deg);
            filter: blur(3px);
            animation: ${rotate} 3s linear infinite;
          }
        }
        &:nth-child(3) {
          width: 30%;
          &:before {
            content: '';
            position: absolute;
            background-color: #b4b3b3ac;
            height: 15px;
            width: 30px;
            transform: rotate(116deg);
            filter: blur(3px);
            animation: ${rotate} 3.5s linear infinite;
          }
        }
        &:before {
          content: '';
          position: absolute;
          background-color: #b4b3b3ac;
          height: 15px;
          width: 30px;
          transform: rotate(116deg);
          filter: blur(3px);
          animation: ${rotate} 2s linear infinite;
        }
      }
    }
  }
  .card-h-href {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 'card-image card-image card-detail card-detail card-detail';
    .card-image {
      grid-area: card-image;
    }
    .card-detail {
      grid-area: card-detail;
      width: 100%;
    }
  }
  /* ---------------------------------- HOVER --------------------------------- */
  &:hover {
    .card-image {
      img {
        transform: scale(1.1);
      }
      .badge small {
        &::before {
          transform-origin: left;
          transform: scaleX(1);
        }
      }
    }
    .card-content {
      .title {
        color: var(--secondary-color)
      }
    }
  }
  .card-image {
    position: relative;
    aspect-ratio: 16/9;
    background-image: url(${APP_IMG}/images/default.jpg);
    background-size: cover;
    background-repeat: repeat;
    overflow: hidden;
    background-position: center;
    .resolution-image {
      box-shadow: 0px 0px 2px 0px #b7b7b7;
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0;
    }
    /* ---------------------------------- IMAGE --------------------------------- */
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 16/9;
      transform-origin: 50% 65%;
      transition: transform 2s, filter 0.5s ease-out;
      /* cls */
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        color: #000;
        filter: drop-shadow(0px 0px 4px #fff);
      }
    }
    .play-btn-gallery {
      position: absolute;
      display: flex;
      top: 4%;
      left: 2%;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 1px;     
      filter: drop-shadow(0px 4px 6px #000);
      svg {
        color: var(--primary-color);
   
      }
    }
  }
  .card-detail {
    position: relative;
    padding: 5px 15px 0 15px;
    margin: auto;
    /* ---------------------------------- BADGE --------------------------------- */
    .badge {
      position: relative;
      /* top: 10px;
      left: 10px; */
      width: 100%;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      background: var(--primary-gradient);
      max-width: max-content;
      margin-bottom: 10px;
      small {
        position: relative;
        line-height: 1.35;
        color: var(--text-color);
        font-weight: 300;
        font-size: 12px;
        text-decoration: none;
        text-transform: capitalize;
        /* animation */
        transition: All 0.2s ease-in;
        padding: 0;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 0px;
          background-color: var(--tertiary-color);
          bottom: 0;
          left: 0;
          transform-origin: left;
          transform: scaleX(0);
          transition: transform 0.3s ease-in-out;
        }
        /* animation */
      }
    }
  }
  /* ---------------------------------- TITLE --------------------------------- */

  .title {
    position: relative;
    min-height: 46px;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 1.45;
    font-weight: 500;
    color: ${props => props.color};
    transition: all .3s ease-in-out;
    @media (max-width: 767px) {
      font-size: 16px;
      min-height: 35px;
    }
    @media (max-width: 690px) {
      font-size: 14px;
    }
  }
  /* ---------------------------------- BLURB --------------------------------- */
  .blurb {
    margin: 5px 0;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 1.45;
    font-weight: 500;
    font-family: var(--front-thin), sans-serif;
    color: #5b5e7a;

    @media (max-width: 767px) {
      margin: 2.5px 0;
    }
  }
  /* --------------------------------- FOOTER --------------------------------- */
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    color: #939393;
    font-family: var(--front-thin), sans-serif;
    margin: 5px 0;
    .center-content {
      display: flex;
      align-items: center;

      p.byline {
        display: flex;
        align-items: center;
        color: var(--secondary-color);
        font-size: 14px;
        font-weight: 500;
        &::after {
          content: '/';
          margin-left: 5px;
          color: #aaaaaa;
        }
        svg {
          margin-right: 5px;
          color: var(--secondary-color);
        }
      }
      .published-date {
        display: flex;
        align-items: center;
        gap: 5px;
        letter-spacing: 0;
        margin-left: 5px;
        /* font-size: 14px; */
        font-weight: 500;
        small,
        svg {
          color: #aaaaaa;
          
          @media (max-width: 690px) {
            font-size: 12px;
          }
        }
      }

      .count-view {
        display: flex;
        align-items: center;
        margin-left: 5px;
        /* font-size: 14px; */
        font-weight: 500;
        gap: 5px;
        small,
        svg {
          color: #aaaaaa;
          @media (max-width: 690px) {
            font-size: 12px;
          }
        }
      }
    }
    small {
      text-transform: lowercase;
      font-size: 12px;
      font-weight: 300;
      line-height: 1;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    .readmore {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--secondary-color);
      border: 1px solid var(--secondary-color);
      padding: 5px 10px;
      small {
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
`;
export default memo(CardH);
